/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';
import PostBlock from './PostBlock';
import Ad from '../Ad/Ad';
import { AdSizes } from '../Ad/AdSizes';
import MagniteAd from '../Ad/MagniteAd';
import globalValues from '../../static-data/global-values.json';

const { cesDataAdsMagniteEnabled } = globalValues?.data?.corusAdsSettings;

export default function PostBlockGridWithAds(props) {
	const {
		posts,
		isLoader = false,
		showPostType = false,
		adConfig = {
			adStartPosition: 3,
			adName: 'post-grid-ad',
			loadGoogleAds: false,
			loadMagniteAds: false,
		},
		className = 'post-block-grid',
		passQueryVar = false,
	} = props;
	const {
		adStartPosition, loadGoogleAds, loadMagniteAds, adName = 'post-grid-ad',
	} = adConfig;
	const router = useRouter();
	const [page, setPage] = useState(1);
	const blocksPerRow = 3;
	const blocksPerPage = 12;
	const FIRST_AD_POS = adStartPosition || 3; // Position of first block with ad

	const loaderBlocks = [{ postId: 'loader1' }, { postId: 'loader2' }, { postId: 'loader3' }];

	const insertAd = (index) => {
		if (index <= 2) {
			return;
		}

		if (index === FIRST_AD_POS && cesDataAdsMagniteEnabled && loadMagniteAds) {
			return (<MagniteAd wrapperClass={adName} />);
		}

		if (!loadGoogleAds) return;

		// eslint-disable-next-line no-plusplus
		for (let i = 1; i <= page; i++) {
			if (index === FIRST_AD_POS || (i > 1 && index === ((i * blocksPerPage) - blocksPerRow))) {
				const adIndex = (cesDataAdsMagniteEnabled && loadMagniteAds) ? i - 1 : i; // First block google ad if no magnite
				const adPos = adStartPosition ? (adStartPosition + i - 1) : (adIndex + 1);
				return (
					<Ad
						key={`${adName}-${adIndex}`}
						sizes={{
							'xs sm': AdSizes.BIG_BOX,
							'md lg xl xxl': AdSizes.LEADERBOARD_BIGBOX_COMBO,
						}}
						htmlId={`${adName}-${adIndex}`}
						wrapperClass={`post-grid-ad ${adName}-${adIndex} ${adName}`}
						pos={`${adPos}`}
					/>
				);
			}
		}

		return null;
	};

	const setCurrentPage = () => {
		const queryParams = new URLSearchParams(window.location.search);

		const pageNum = parseInt(queryParams.get('page'), 10);

		if (pageNum) {
			setPage(pageNum);
		}
	};

	useEffect(() => {
		setCurrentPage();

		router.events.on('routeChangeComplete', () => {
			setCurrentPage();
		});
	}, [router.events]);

	return (
		<div className={`${className} with-ads`}>
			{isLoader
				? loaderBlocks.map((post) => (<PostBlock key={post.postId} post={post} isLoader />))
				: posts.map((post, index) => (
					<Fragment key={post.postId}>
						{insertAd(index)}
						<PostBlock key={post.postId} post={post} passQueryVar={passQueryVar} showPostType={showPostType} />
					</Fragment>
				))}
		</div>
	);
}

PostBlockGridWithAds.propTypes = {
	className: PropTypes.string,
	posts: PropTypes.array.isRequired,
	isLoader: PropTypes.bool,
	showPostType: PropTypes.bool,
	adConfig: PropTypes.shape({
		adStartPosition: PropTypes.number,
		loadGoogleAds: PropTypes.bool,
		loadMagniteAds: PropTypes.bool,
		adName: PropTypes.string,
	}),
	passQueryVar: PropTypes.bool,
};
