import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from './ShortVideosDrawerControls';
import ShortVideosDrawerItem from './ShortVideosDrawerItem';
import ShortVideosDrawerLightbox from './ShortVideosDrawerLightbox';
import 'slick-carousel/slick/slick.css';

export default function ShortVideosDrawerItems({ shortVideos }) {
	const [lightboxVideoIndex, setLightboxVideoIndex] = useState(null);

	const DrawerMainRef = useRef(null);

	const sliderUpdated = () => {
		const activeItems = document.querySelectorAll('.slick-slide');

		// Only active items should be focusable. For aria conformance.
		activeItems.forEach((item) => {
			if (item.getAttribute('aria-hidden') === 'true') {
				item.querySelector('button')?.setAttribute('tabindex', '-1');
			} else {
				item.querySelector('button')?.setAttribute('tabindex', '0');
			}
		});
	};

	useEffect(() => {
		sliderUpdated();
	}, []);

	const openLightbox = (index) => {
		setLightboxVideoIndex(index);
	};

	const closeLightbox = () => {
		setLightboxVideoIndex(null);
	};

	if (!shortVideos) return null;

	const settings = {
		arrows: true,
		infinite: false,
		mobileFirst: true,
		slidesToShow: 4.6,
		slidesToScroll: 4,
		focusOnSelect: false,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1366,
				settings: {
					slidesToShow: 4.6,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3.5,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1,
				},
			},
		],
		afterChange: () => sliderUpdated(),
	};

	return (
		<>
			<div className="short-videos-drawer__items">
				<Slider ref={DrawerMainRef} {...settings}>
					{shortVideos.map((video, index) => (
						<ShortVideosDrawerItem
							key={video.guid}
							video={video}
							index={index + 1}
							onOpenLightbox={() => openLightbox(index + 1)}
						/>
					))}
				</Slider>
			</div>
			{lightboxVideoIndex !== null && (
				<ShortVideosDrawerLightbox
					videoIndex={lightboxVideoIndex}
					videos={shortVideos}
					onClose={closeLightbox}
				/>
			)}
		</>
	);
}

ShortVideosDrawerItems.propTypes = {
	shortVideos: PropTypes.array,
};
