import PropTypes from 'prop-types';

export const NextArrow = ({ className, onClick = () => {} }) => (
	<button
		className={className}
		onClick={onClick}
		type="button"
	>
		Next
	</button>
);

export const PrevArrow = ({ className, onClick = () => {} }) => (
	<button
		className={className}
		onClick={onClick}
		type="button"
	>
		Previous
	</button>
);

PrevArrow.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
};

NextArrow.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
};
