import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useCurrentUrl from '../../hooks/useCurrentUrl';
import useSessionStorage from '../../hooks/useSessionStorage';

import { createSerializationId } from '../../utils/analytics';
import { getContentAuthors, getFormattedDate } from '../../utils/utility';

// Global Data
import globalValues from '../../static-data/global-values.json';

const adobeApp = globalValues?.data?.corusAdobeAnalyticsSettings?.adobe_app || {};
const appName = adobeApp?.name ?? 'corus';

const serializationIdPrefix = globalValues?.data?.corusAdobeAnalyticsSettings?.adobe_misc_settings?.serializationIdPrefix || 'CE';

/**
 * @summary Renders initial Adobe Analytics data layer object and updates `window.codexData` with it in a `useEffect`.
 *
 * If you set `viewType="content"`, this component will also add fields for `window.codexData.content`.
 *
 * This component automatically generates several data layer properties using the current URL, constructed via `AppContext`
 * and the Next.js Router.
 *
 * @param {import('./AdobeAnalyticsDataLayerTypes').AdobeAnalyticsDataLayerProps} props
 * @returns {JSX.Element}
 */
export default function AdobeAnalyticsDataLayer({
	viewName = '',
	viewPath = '',
	viewURL = '',
	viewLanguage = 'en-CA',
	viewType = '',
	contentID = '',
	contentType = '',
	contentCreatedDate = '',
	contentPublishedDate = '',
	contentModifiedDate = '',
	contentTitle = '',
	contentCategory = '',
	contentTags = [],
	contentAuthors = [],
	contentHasVideo = false,
	contentRating = '',
	contentRecipeLength = '',
	contentReviews = '',
	initialEvents = {},
	shouldRecordInitialSerializationId = false,
}) {
	const currentUrl = useCurrentUrl();

	const computedPath = viewPath || currentUrl.pathname;

	// Prepare the path hierarchy
	const fullPathHierarchy = computedPath.split('/').filter(Boolean);

	// Drop the last element, which is the slug, unless there is only one element
	// i.e.,
	//   /foo/ => []
	//   /article/foo => ['article']
	//   /foo/bar/baz => ['foo', 'bar']
	const pathHierarchy = (
		fullPathHierarchy.length > 1
			? fullPathHierarchy.slice(0, fullPathHierarchy.length - 1)
			: []
	);

	// If view name is provided, use it;
	// otherwise if this has a content view type, use its slug as the view name;
	// otherwise, use an empty string.
	const computedViewName = viewName || fullPathHierarchy[fullPathHierarchy.length - 1];

	// Prepare the view url - use `viewURL` if non-empty; otherwise create it using the current URL.
	const pathURL = viewURL || currentUrl.toString();

	// Prepare the view referrer
	const referrerURL = useSessionStorage('pagePrevious', currentUrl.origin);

	/* * * *  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	/* DATA LAYER CONSTRUCTION                                                                                          */
	/* * * *  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	let contentDataLayer = null;

	// Add general content information
	if (viewType === 'content') {
		contentDataLayer = {
			content: {
				id: contentID,
				type: contentType,
				createdDate: getFormattedDate(contentCreatedDate),
				publishedDate: getFormattedDate(contentPublishedDate),
				modifiedDate: getFormattedDate(contentModifiedDate),
				title: contentTitle,
			},
		};
	}

	// Add other conditional content info
	if (viewType === 'content') {
		// add tags
		if (contentTags.length > 0) {
			contentDataLayer.content.tags = contentTags;
		}
		// add category
		if (contentCategory) {
			contentDataLayer.content.category = contentCategory;
		}
		// add authors to all content types except profile pages
		if (!['staff', 'host', 'chef'].includes(contentType)) {
			contentDataLayer.content.authors = getContentAuthors(contentAuthors);
		}
		// add has videos to posts and galleries
		// excluding pages and profile pages
		if (!['page', 'host', 'chef', 'staff'].includes(contentType)) {
			contentDataLayer.content.hasVideo = contentHasVideo ?? false;
		}

		// add recipe rating, reviews and recipe length to recipes
		if (contentType === 'recipe') {
			// add rating
			if (contentRating) {
				contentDataLayer.content.rating = contentRating;
			}
			// add reviews
			if (contentReviews) {
				contentDataLayer.content.reviews = contentReviews;
			}
			// add recipe length
			if (contentRecipeLength) {
				contentDataLayer.content.recipeLength = contentRecipeLength;
			}
		}
	}

	let flowDataLayer = null;

	if (shouldRecordInitialSerializationId) {
		flowDataLayer = {
			flow: {
				name: `${appName}-newsletter-signup`,
				serializationid: createSerializationId(serializationIdPrefix),
				stepName: 'start',
			},
		};
	}

	const dataLayer = {
		app: {
			...adobeApp,
			__typename: undefined, // Omit `__typename` field from GraphQL
		},
		events: {
			view: true,
			...initialEvents,
		},
		view: {
			name: computedViewName,
			hierarchy: pathHierarchy,
			url: pathURL,
			referrer: referrerURL,
			language: viewLanguage,
			type: viewType,
		},
		...contentDataLayer,
		...flowDataLayer,
	};

	/* * * *  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	/* DATA LAYER RENDER                                                                                                */
	/* * * *  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	useEffect(() => {
		window.codexData = dataLayer;
		// Effect should run once on render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="adobe-analytics-js" />
	);
}

export const DataLayerPropTypes = {
	viewName: PropTypes.string,
	viewPath: PropTypes.string,
	viewURL: PropTypes.string,
	viewLanguage: PropTypes.string,
	viewType: PropTypes.string,
	contentID: PropTypes.number,
	contentType: PropTypes.string,
	contentCreatedDate: PropTypes.string,
	contentPublishedDate: PropTypes.string,
	contentModifiedDate: PropTypes.string,
	contentTitle: PropTypes.string,
	contentCategory: PropTypes.string,
	contentTags: PropTypes.arrayOf(PropTypes.string),
	contentAuthors: PropTypes.arrayOf(PropTypes.object),
	contentHasVideo: PropTypes.bool,
	contentRating: PropTypes.number,
	contentRecipeLength: PropTypes.number,
	contentReviews: PropTypes.number,
	initialEvents: PropTypes.object,
	shouldRecordInitialSerializationId: PropTypes.bool,
};

AdobeAnalyticsDataLayer.propTypes = DataLayerPropTypes;
