import PropTypes from 'prop-types';
import ShortVideosDrawerItems from './ShortVideosDrawerItems';

export default function ShortVideosDrawer({ shortVideos }) {
	return (
		shortVideos && (
			<div className="short-videos-drawer">
				<div className="short-videos-drawer__wrapper">
					<h2 className="short-videos-drawer__heading">Featured Shorts</h2>
					<ShortVideosDrawerItems shortVideos={shortVideos} />
				</div>
			</div>
		)
	);
}

ShortVideosDrawer.propTypes = {
	shortVideos: PropTypes.array,
};
