import React from 'react';
import PropTypes from 'prop-types';

/**
 * @summary Simple ARIA announcer.
 *
 * From https://www.emgoto.com/react-search-bar/#search-as-you-type-spa-navigation-and-accessibility-concerns
 *
 * !!!
 * **Use `isAssertive` with caution, as the ARIA announcer will interrupt the screen-reader,**
 * **which can become very annoying very quickly.**
 * !!!
 *
 * @param {object} props
 * @param {string} props.message Message to announce.
 * @param {boolean} [props.isAssertive] Whether or not the ARIA announcer should be assertive.
 * @returns
 */
function Announcer({ message, isAssertive = false }) {
	return (
		<div
			role="status"
			aria-live={isAssertive ? 'assertive' : 'polite'}
			className="visually-hidden"
		>
			{message}
		</div>
	);
}

export default Announcer;

Announcer.propTypes = {
	isAssertive: PropTypes.bool,
	message: PropTypes.string.isRequired,
};
