import PropTypes from 'prop-types';
import { useState } from 'react';
import NewsletterTerms from './Messages/NewsletterTerms';
import NewsletterError from './Messages/NewsletterError';
import NewsletterSuccess from './Messages/NewsletterSuccess';
import { handleSuccessMessageAnalytics } from '../../utils/analytics';
import { isValidEmailAddress } from '../../utils/utility';

export default function NewsletterForm({
	details = {
		title: '',
		newsletterRequestVerificationToken: '',
		newsletterEmailPlaceholderValue: 'Your Email',
	},
}) {
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const [query, setQuery] = useState({
		s_email: '',
		s_brand: details.newsletterSBrand,
		cr: details.newsletterCrValue,
		fm: details.newsletterFmValue,
		__RequestVerificationToken: details.newsletterRequestVerificationToken ?? '',
	});

	// update input value
	const handleParam = (e) => {
		const { name } = e.target;
		const { value } = e.target;

		setQuery((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	// validate email
	const emailValidation = () => {
		const isValid = isValidEmailAddress(query.s_email);
		if (!isValid) {
			// set error state to true
			setError(true);
			return false;
		}
		setError(false);
		return true;
	};

	// handle form submit
	const formSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		Object.entries(query).forEach(([key, value]) => {
			formData.append(key, value);
		});
		// check to see if email is valid before sending the data
		if (emailValidation()) {
			try {
				const response = await fetch(details.newsletterPostUrlFrontend, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
					},
					body: formData,
				});
				if (response.status === 200) {
					// form was submitted and confirmed redirection
					setSuccess(true);

					handleSuccessMessageAnalytics();
				} else {
					setSuccess(false);
				}
			} catch (_err) {
				setSuccess(false);
			}
		}
	};

	return (
		<>
			{ !success && (
				<>
					<div className="newsletter-block__title">
						{details.newsletterHeading && <h2>{details.newsletterHeading}</h2>}
					</div>
					<div className="newsletter-block__form">
						<form acceptCharset="utf-8" onSubmit={formSubmit}>
							<div className="newsletter-block__input-fields-wrapper">
								<input type="hidden" name="cr" value={query.cr} />
								<input type="hidden" name="fm" value={query.fm} />
								<input type="hidden" name="s_brand" id="s_brand" value={query.s_brand} />
								<input name="__RequestVerificationToken" type="hidden" value={query.__RequestVerificationToken} />
								<div className="input-field email-input">
									<input
										type="text"
										name="s_email"
										id="s_email"
										placeholder={details.newsletterEmailPlaceholderValue}
										aria-label="Enter your email address to sign up for the newsletter"
										value={query.s_email}
										onChange={handleParam}
									/>
								</div>
								<div className="input-field email-submit">
									<input
										className="button-primary"
										type="submit"
										value={details.newsletterSubmitButtonValue}
										aria-label="Sign up for the newsletter"
									/>
								</div>
							</div>
						</form>
						<NewsletterTerms termsMessage={details.newsletterTermsAndCondition} />
						{ error && <NewsletterError errorMessage={details.newsletterEmailErrorMessage} /> }
					</div>
				</>
			)}
			{ success && <NewsletterSuccess successMessage={details.newsletterSuccessMessage} /> }
		</>
	);
}

NewsletterForm.propTypes = {
	details: PropTypes.shape({
		newsletterHeading: PropTypes.string,
		newsletterPostUrlFrontend: PropTypes.string.isRequired,
		newsletterCrValue: PropTypes.number.isRequired,
		newsletterFmValue: PropTypes.number.isRequired,
		newsletterSBrand: PropTypes.string.isRequired,
		newsletterRequestVerificationToken: PropTypes.string,
		newsletterEmailPlaceholderValue: PropTypes.string,
		newsletterSubmitButtonValue: PropTypes.string.isRequired,
		newsletterSuccessMessage: PropTypes.string.isRequired,
		newsletterEmailErrorMessage: PropTypes.string.isRequired,
		newsletterTermsAndCondition: PropTypes.string.isRequired,
	}),
};
