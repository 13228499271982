import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from './ShortVideosDrawerControls';
import VmsVideo from '../video/VmsVideo/VmsVideo';
import { safeJWPlayerAction } from '../../utils/utility';

export default function ShortVideosDrawerLightbox({ videoIndex, videos, onClose }) {
	const lightboxRef = useRef(null);
	const DrawerRef = useRef(null);

	/**
	 * Close the lightbox
	 */
	const handleCloseLightbox = useCallback(() => {
		onClose();
	}, [onClose]);

	/**
	 * Handle keydown event
	 */
	const handleKeyDown = useCallback((event) => {
		if (!DrawerRef.current) return;

		const slider = DrawerRef.current;
		switch (event.key) {
		case 'Escape':
			handleCloseLightbox();
			break;
		case 'ArrowRight':
			event.preventDefault();
			slider.slickNext();
			break;
		case 'ArrowLeft':
			event.preventDefault();
			slider.slickPrev();
			break;
		default:
		}
	}, [handleCloseLightbox]);

	/**
	 * Play and pause video when slide changes
	 * @param {*} current
	 * @param {*} next
	 */
	const handleSlideChange = (current, next) => {
		// Pause all videos
		videos.forEach((_, index) => {
			safeJWPlayerAction(index + 1, 'pause');
		});

		// Play the current slide's video
		safeJWPlayerAction(next + 1, 'play');
	};

	const settings = {
		arrows: true,
		infinite: false,
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		focusOnSelect: false,
		swipeToSlide: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		beforeChange: (current, next) => handleSlideChange(current, next),
	};

	useEffect(() => {
		document.body.classList.toggle('short-videos-drawer-lightbox-open', onClose);
		document.addEventListener('keydown', handleKeyDown);

		// Go to the video
		if (DrawerRef.current) {
			DrawerRef.current.slickGoTo(videoIndex - 1, false);

			// Ensure the initial video plays
			// We need to setup a delay to make sure the video plays again after lightbox closes
			// This could be something to look at in the future
			const timeoutId = setTimeout(() => {
				safeJWPlayerAction(videoIndex, 'play');
			}, 1000);

			// Return cleanup function
			return () => {
				clearTimeout(timeoutId);
				document.body.classList.remove('short-videos-drawer-lightbox-open');
				document.removeEventListener('keydown', handleKeyDown);

				// Pause all videos
				videos.forEach((_, index) => {
					safeJWPlayerAction(index + 1, 'pause');
				});
			};
		}

		return () => {};
	}, [onClose, videoIndex, videos, handleKeyDown]);

	return (
		<FocusTrap
			active
			ref={lightboxRef}
			focusTrapOptions={{
				clickOutsideDeactivates: true,
				escapeDeactivates: true,
				fallbackFocus: '.short-videos-drawer__lightbox-close',
				onDeactivate: onClose,
			}}
		>
			<div className="short-videos-drawer__lightbox">
				<div
					className="short-videos-drawer__lightbox-wrapper"
					role="dialog"
					aria-modal="true"
				>
					<div className="short-videos-drawer__lightbox-main">
						<Slider ref={DrawerRef} {...settings}>
							{videos.map((videoItem, index) => (
								<div
									key={videoItem.guid}
									className="short-videos-drawer__lightbox-item"
								>
									<VmsVideo
										id={videoItem.guid}
										customConfig={
											{
												divId: index + 1,
												autostart: index + 1 === videoIndex,
											}
										}
									/>
								</div>
							))}
						</Slider>
					</div>
					<button
						onClick={handleCloseLightbox}
						className="short-videos-drawer__lightbox-close"
						aria-label="Close lightbox"
						type="button"
					>
						✕
					</button>
				</div>
			</div>
		</FocusTrap>
	);
}

ShortVideosDrawerLightbox.propTypes = {
	videoIndex: PropTypes.number.isRequired,
	videos: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
};
