import PropTypes from 'prop-types';
import fallbackImage from '../../inline-images/video-short-fallback.jpg';
import CorusImage from '../CorusImage/CorusImage';
import { secondsToMinutes } from '../../utils/utility';
import useSwipeDetection from '../../hooks/useSwipeDetection';

export default function ShortVideosDrawerItem({ video, index, onOpenLightbox }) {
	const videoThumbnail = video?.resources.filter((img) => img.tag === 'thumbnail');
	const videoImage = videoThumbnail?.[0]?.uri ? `${videoThumbnail?.[0]?.uri}?w=540` : fallbackImage?.src;
	const { handleMouseDown, dragging } = useSwipeDetection();

	const handleOpenLightbox = (event) => {
		if (!dragging) {
			event.preventDefault();
			onOpenLightbox(index + 1);
		}
	};

	return (
		<button
			className="short-videos-drawer__item"
			type="button"
			aria-label="Watch the short video"
			onClick={handleOpenLightbox}
			onMouseDownCapture={handleMouseDown}
		>
			<div className="short-videos-drawer__image-wrapper">
				<CorusImage
					src={videoImage}
					alt={video?.data?.title}
					width={270}
					height={480}
				/>
				<div className="short-videos-drawer__overlay" />
			</div>
			<div className="short-videos-drawer__details-wrapper">
				<h3 className="short-videos-drawer__video-title">
					{video?.data?.title}
				</h3>
				<div className="short-videos-drawer__video-control">
					<div className="short-videos-drawer__video-play-icon">
						<span />
					</div>
					<div className="short-videos-drawer__video-duration">
						{secondsToMinutes(video?.data?.duration)}
					</div>
				</div>
			</div>
		</button>
	);
}

ShortVideosDrawerItem.propTypes = {
	video: PropTypes.object,
	index: PropTypes.number,
	onOpenLightbox: PropTypes.func.isRequired,
};
