import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { decode } from 'html-entities';

const ACCEPTED_HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

/**
 * @summary Render a Heading, decoding any HTML entities passed along.
 * @param {object} props
 * @param {('h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6')} props.as Which heading to render.
 * @param {React.ReactNode} props.children Text to render.
 * @param {string} [props.className] Additionl CSS class names to pass.
 * @param {string} [props.ariaLabel] ARIA label to add to the heading.
 *
 * Please note that while `children` accepts a `React.ReacNode` as usual,
 * this component expects `children` to be a regular string, per its `propTypes`.
 * If this component receives a non-`string` child, it will render nothing.
 * You will also see a failed prop type warning to notify you.
 */
export default function Heading({
	as: H = 'h6',
	children: text = '',
	className,
	ariaLabel,
}) {
	if (ACCEPTED_HEADINGS.includes(H) && typeof text === 'string') {
		const sanitizedText = DOMPurify.sanitize(
			decode(text),
			{
				ALLOWED_TAGS: [], // Forbid all tags.
			},
		);

		return (
			<H className={className} aria-label={ariaLabel}>
				{sanitizedText}
			</H>
		);
	}
	return <></>;
}

Heading.propTypes = {
	as: PropTypes.oneOf(ACCEPTED_HEADINGS),
	children: PropTypes.string,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
};
