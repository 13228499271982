import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { decode } from 'html-entities';
import { isOriginSameAsLocation } from '../../utils/utility';

/**
 * @summary Render a link, checks if the link is internal or external. If the link is internal,
 * then use the nextjs Link component, if the link is external, then use the regular anchore tag.
 * @param {object} props
 */
export default function CustomLink({ href, className, children }) {
	return isOriginSameAsLocation(href) ? (
		(
			<Link href={decode(href)} className={className}>

				{children}

			</Link>
		)
	) : (
		<a
			href={decode(href)}
			rel="noopener noreferrer"
			className={className}
		>
			{children}
		</a>
	);
}

CustomLink.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
};
